

.trade-cards{ transition: 0.25s; }

.trade-cards:hover{ transform: scale(1.05)!important; }



@media only screen and (max-width: 768px){

}



@media only screen and (max-width: 575.98px){



}