



.login-card{ min-width: 500px!important; }
.login-logo{ height:150px; }



@media only screen and (max-width: 768px){


    .login-card{ min-width: 600px!important; }

}



@media only screen and (max-width: 575.98px){


    .login-card{ min-width: 350px!important; }
    .login-logo{ height:120px; }



}