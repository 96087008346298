


.pol_logo{ height: 90px; }

.smj_logo{ height: 90px; }




@media only screen and (max-width: 768px){

    .smj_logo{ height: 60px; }

    .pol_logo{ height: 50px; }

}



@media only screen and (max-width: 575.98px){


    .smj_logo{ display: none; }

    .pol_logo{ height: 50px; }



}