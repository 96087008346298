
/************************************************
 			*FONT-SECTION-STARTS*
 ************************************************/

html,body{font-family: 'Poppins', sans-serif; font-size: 14px;}


.fs-cal {font-family: Calibri!important;}

.text-justify{text-align: justify!important;}


.fs-08	{font-size: 08px!important;}
.fs-10	{font-size: 10px!important;}
.fs-12	{font-size: 12px!important;}
.fs-14	{font-size: 14px!important;}
.fs-16	{font-size: 16px!important;}
.fs-18	{font-size: 18px!important;}
.fs-20	{font-size: 20px!important;}
.fs-22	{font-size: 22px!important;}
.fs-24	{font-size: 24px!important;}
.fs-26	{font-size: 26px!important;}
.fs-28	{font-size: 28px!important;}
.fs-30	{font-size: 30px!important;}
.fs-32	{font-size: 32px!important;}
.fs-34	{font-size: 34px!important;}


.fw-200 { font-weight: 200!important; }
.fw-300 { font-weight: 300!important; }
.fw-400 { font-weight: 400!important; }
.fw-500 { font-weight: 500!important; }
.fw-600 { font-weight: 600!important; }
.fw-700 { font-weight: 700!important; }
.fw-800 { font-weight: 800!important; }
.fw-900 { font-weight: 900!important; }



.bg-grad{ background-image: -webkit-linear-gradient(60deg, #F8B460, #eeeeee); }

.theme-1 { background-color: #ffffff!important; }
.theme-2 { background-color: #e2e8f0!important; }
.theme-3 { background-color: #626262!important; }


.text-1 { color: #ffffff!important; }
.text-2 { color: #e2e8f0!important; }
.text-3 { color: #626262!important; }


.bord-1 { border: #ffffff!important; }
.bord-2 { border: #e2e8f0!important; }
.bord-3 { border: #626262!important; }


.lh-10 { line-height: 10%!important; }
.lh-20 { line-height: 20%!important; }
.lh-30 { line-height: 30%!important; }
.lh-40 { line-height: 40%!important; }
.lh-50 { line-height: 50%!important; }
.lh-60 { line-height: 60%!important; }
.lh-70 { line-height: 70%!important; }
.lh-80 { line-height: 80%!important; }
.lh-90 { line-height: 90%!important; }
.lh-100 { line-height: 100%!important; }
.lh-110 { line-height: 110%!important; }
.lh-120 { line-height: 120%!important; }


.lh-15 { line-height: 10%!important; }
.lh-25 { line-height: 20%!important; }
.lh-35 { line-height: 30%!important; }
.lh-45 { line-height: 40%!important; }
.lh-55 { line-height: 50%!important; }
.lh-65 { line-height: 60%!important; }
.lh-75 { line-height: 70%!important; }
.lh-85 { line-height: 80%!important; }
.lh-95 { line-height: 90%!important; }
.lh-105 { line-height: 100%!important; }
.lh-115 { line-height: 110%!important; }
.lh-125 { line-height: 120%!important; }



/************************************************
 			*FONT-SECTION-STARTS*
 ************************************************/
