

/*********************_PAGE NUMBER CUSTOM STARTS_*********************/

                /*BUTTON LINK STYLE*/
.dataTables_wrapper .dataTables_paginate .paginate_button a{
  text-decoration: none!important;
  color: #FFFFFF;
  border: none!important;
}

                    /*HOVER BUTTON COLOR*/
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #aaaaaa;
  color: #000000!important;
/*  border: none!important;*/
  padding: 0 1px;
  border-color: #0D67A5;
}


/*below block of css for change style when active*/

/*.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: none;
  color: black!important;
}*/


/*********************_PAGE NUMBER CUSTOM ENDS_*********************/

.dataTables_length{
  display: flex!important;
  justify-content: left!important;
}



.dataTables_wrapper .dataTables_paginate .paginate_button{
  text-decoration: none!important;
  background-color: !important;
  padding: 7px 8px!important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button, .paginate_active a {
    display: inline-block;
    background-color: #1E3D58;
    padding: 2px 6px;
    margin-left: 2px;
    cursor: pointer;
    *cursor: hand;
}

.paginate_button .paginate_active:hover  {
    display: inline-block;
    background-color: red;
    padding: 2px 6px;
    margin-left: 2px;
    cursor: pointer;
    *cursor: hand;
}


table.dataTable thead,tfoot{
  color: #0D67A5!important;
  font-weight: 800!important;
}

.dataTables_wrapper .dataTables_filter label{
  color: #0D67A5!important;
}

.dataTables_wrapper .dataTables_length label{
  color: #0D67A5!important;
}

.dataTables_wrapper .dataTables_info{
  color: #0D67A5!important;
}

.paginate_button.active{
  background-color: #ABCADF!important;
  color: red!important;
}

.paginate_button.active a{
  background-color: #ABCADF!important;
  color: #000000!important;
}





.paginate_button.disabled {
display: none!important;
}


@media only screen and (max-width: 641px){

/*  .pagination{
    justify-content: center!important;
  }*/

}